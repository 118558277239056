import styled from "styled-components";

import { Container, Icon } from "components/ui";

import { media } from "constants/media";

export const StyledNotFound = styled.div`
    background-color: var(--color-orange-60);
    color: var(--color-white);
    width: 100%;
`;

export const StyledContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    padding: 42px 24px;

    ${media.mdUp} {
        flex-direction: row;
        padding: 80px 0 0;
    }
`;

export const Title = styled.div`
    font-size: 56px;
    line-height: 80px;
    margin-bottom: 8px;

    ${media.mdUp} {
        font-size: 96px;
        line-height: 120px;
        margin: 0 40px 0 0;
    }
`;

export const Description = styled.div`
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 32px;

    ${media.mdUp} {
        margin: 0 64px 0 0;
        width: 240px;
    }

    ${media.lgUp} {
        margin: 0 161px 0 0;
        width: 240px;
    }
`;

export const Links = styled.div`
    font-size: 96px;
    font-style: normal;
    line-height: 120px;
    margin-bottom: 32px;

    ${media.mdUp} {
        margin: 0;
    }
`;

export const LinksHeader = styled.div`
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
    margin-bottom: 16px;
    text-transform: uppercase;
    width: 100%;

    ${media.mdUp} {
        margin: 0 161px 16px 0;
        width: 263px;
    }
`;

export const LinksWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${media.mdUp} {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const LinkIcon = styled(Icon)`
    margin-right: 8px;
    transition-duration: var(--default-transition-duration);
    transition-property: transform;
    transition-timing-function: ease;
`;

export const LinkItem = styled.a`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 800;
    line-height: 24px;
    text-decoration: none;
    width: 100%;

    &:hover {
        ${LinkIcon} {
            transform: translateX(8px);
        }
    }

    ${media.mdUp} {
        font-size: 15px;
        width: 50%;
    }
`;

export const ImageWrapper = styled.div`
    text-align: center;
    width: 100%;
`;
