import Image from "next/legacy/image";
import Link from "next/link";

import { ARROW_RIGHT } from "components/ui";
import {
    Description,
    ImageWrapper,
    LinkIcon,
    LinkItem,
    Links,
    LinksHeader,
    LinksWrapper,
    StyledContainer,
    StyledNotFound,
    Title
} from "components/notFound/NotFound.styled";

import { useMediaQueryContext } from "helpers/hooks";

const linkItems = [
    { name: "Alle vacatures", url: "/vacatures" },
    { name: "ZZP’ers", url: "/zzper" },
    { name: "Zorgprofessionals", url: "/zorgprofessionals" },
    { name: "Nieuws", url: "/nieuws" },
    { name: "Opdrachtgevers", url: "/opdrachtgevers" },
    { name: "Contact", url: "/contact" }
];

function NotFound(): JSX.Element {
    const { isMobileView } = useMediaQueryContext();

    return (
        <StyledNotFound>
            <StyledContainer>
                {isMobileView && (
                    <ImageWrapper>
                        <Image
                            src="/images/not-found-page.png"
                            width={320}
                            height={120}
                        />
                    </ImageWrapper>
                )}
                <Title>Oeps!</Title>
                <Description>
                    We voelen ons goed, maar we kunnen deze pagina niet (meer)
                    vinden.
                </Description>
                <Links>
                    <LinksHeader>
                        Hier zijn een aantal handige links:
                    </LinksHeader>
                    <LinksWrapper>
                        {linkItems.map(item => (
                            <Link
                                legacyBehavior
                                href={item.url}
                                passHref
                                key={item.name}
                            >
                                <LinkItem>
                                    {item.name}{" "}
                                    <LinkIcon icon={ARROW_RIGHT} size={24} />
                                </LinkItem>
                            </Link>
                        ))}
                    </LinksWrapper>
                </Links>
            </StyledContainer>
            {!isMobileView && (
                <ImageWrapper>
                    <Image
                        src="/images/not-found-page.png"
                        width={750}
                        height={300}
                    />
                </ImageWrapper>
            )}
        </StyledNotFound>
    );
}

export default NotFound;
